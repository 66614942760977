<template>
  <div>
    <BaseTable
      ref="sale-invoice-retention-table"
      :resource="$data.$constants.RESOURCES.RESOURCE_RETENTIONS"
      :resourceStore="'saleInvoice'"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :filters="baseFilters"
      :loading="loading"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewSaleInvoice', params: { id: $event.id } })"
    >
      <template #cell(client)="data">
        <div> {{ data.item.client.name }} </div>
      </template>
      <template #cell(job)="data">
        <div> {{ data.item.job.name }} </div>
      </template>
      <template #cell(total_retention)="{ value, item }">
        <div> {{ value | numberToLocalString }} €</div>
        <div
          v-show="item.retention_expiration"
          class="font-small text-light"
        >
          vence {{ item.retention_expiration | formatDate }}
        </div>
      </template>
      <template #cell(retention_expiration)="data">
        <div>{{ data.item.retention_expiration |formatDate }}</div>
      </template>
      <template #cell(collateral_retention_collected)="{ value }">
        <b-badge
          v-if="value > 0"
          pill
          variant="light-success"
          class="px-1 d-inline-flex align-items-center"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="25"
            color="success"
          />
          <span class="text-capitalize font-weight-light badge-text text-success">
            Si
          </span>
        </b-badge>
        <b-badge
          v-else
          pill
          variant="light-danger"
          class="px-1 d-inline-flex align-items-center"
        >
          <feather-icon
            icon="XCircleIcon"
            size="25"
            color="danger"
          />
          <span class="text-capitalize font-weight-light badge-text text-danger">
            No
          </span>
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <b-link class="d-inline-block text-indigo">
          <feather-icon
            icon="DownloadIcon"
            size="18"
            @click.stop="handlePrintLinkClick(data.item.id)"
          />
        </b-link>
        <b-link
          :to="{ name: 'viewSaleInvoice', params: { id: data.item.id} }"
          class="px-1 d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="handleDeleteIconClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </template>
    </BaseTable>
    <SaleInvoicesRetentionListFilters
      ref="sale-invoices-retention-list-filters"
      v-model="filtersVisible"
      :baseFilters="baseFilters"
      @filters-submit="$store.commit('saleInvoice/setRetentionFilters', $event)"
    />
  </div>
</template>

<script>
import SaleInvoicesRetentionListFilters from '@/components/sale-invoices/filters/SaleInvoicesRetentionListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import { mapGetters } from 'vuex'
import StatusApi from '@/api/status-api'
import { SALE_INVOICES_STATUS_TYPE } from '@/api/status-api'

export default {
  name: 'SaleInvoicesRetentionTable',
  components: {
    BaseTable,
    SaleInvoicesRetentionListFilters,
  },
  data() {
    return {
      columns: [
        {
          label: 'NÚMERO',
          key: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '100px' },
        },
        {
          label: 'JOB',
          key: 'job',
          sortable: true,
        },
        {
          label: 'CLIENTE',
          key: 'client',
          sortable: true,
        },
        {
          label: 'RETENCIÓN',
          key: 'total_retention',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          label: 'VENCIMIENTO',
          key: 'retention_expiration',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          label: 'RECIBIDA',
          key: 'collateral_retention_collected',
          sortable: true,
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '210px' },
        },
      ],
      loading: false,
      filtersVisible: false,
      totalsConfiguration: [
        { colspan: 3 },
        { label: 'TOTAL', key: 'total_retention', unit: '€' },
        { colspan: 2 },
      ],
      statusList: []
    }
  },
  computed: {
    ...mapGetters({
      filters: 'saleInvoice/getRetentionFilters',
    }),
    baseFilters () {
      let filters = { ...this.filters }

      if(!this.filters.status || (this.filters.status && this.filters.status.length === 0)) {
        filters = { ...this.filters, status: this.statusList?.filter(({ name }) => {
          return ''
        })}
      }

      return filters
    }
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit('saleInvoice/setRetentionFilters', {})
      this.$refs['sale-invoices-retention-list-filters'].clearFilters()
    },
    async handlePrintLinkClick(saleInvoiceId) {
      this.loading = true
      try {
        await SaleInvoicesApi.printSaleInvoice(saleInvoiceId)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(saleInvoice) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${saleInvoice.code}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await SaleInvoicesApi.delete(saleInvoice.id)
        await this.$refs['sale-invoice-retention-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    try {
      const response = await StatusApi.list(SALE_INVOICES_STATUS_TYPE)
      this.statusList = response.data.data
    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <b-tabs pills v-model="tabSelected">
    <b-tab title="Facturas">
      <BaseCard
        :caption-visible="false"
        :footer-visible="false"
        actions-visible
      >
        <template #title>
          <span class="h3 font-weight-bold mb-0 text-uppercase">facturas de venta</span>
        </template>
        <template #actions>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            :to="{ name: 'createSaleInvoice' }"
          >
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir factura
            </b-button>
          </b-link>
        </template>
        <SaleInvoicesTable />
      </BaseCard>
    </b-tab>
    <b-tab title="Retenciones">
      <BaseCard
        :caption-visible="false"
        :footer-visible="false"
        actions-visible
      >
        <template #title>
          <span class="h3 font-weight-bold mb-0 text-uppercase">Retenciones</span>
        </template>
        <SaleInvoicesRetentionTable />
      </BaseCard>
    </b-tab>
    <b-tab title="Fra. No asociadas a Job">
      <BaseCard
        :caption-visible="false"
        :footer-visible="false"
        actions-visible
      >
        <template #title>
          <span class="h3 font-weight-bold mb-0 text-uppercase">Fra. No asociadas a job</span>
        </template>
        <template #actions>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            :to="{ name: 'createSaleInvoice' }"
          >
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir factura
            </b-button>
          </b-link>
        </template>
        <SaleInvoicesTable :with-job-mode="false" />
      </BaseCard>
    </b-tab>
  </b-tabs>
</template>

<script>
import SaleInvoicesTable from '@/components/sale-invoices/table/SaleInvoicesTable.vue'
import SaleInvoicesRetentionTable from '@/components/sale-invoices/table/SaleInvoicesRetentionTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: { 
    BaseCard, 
    SaleInvoicesRetentionTable, 
    SaleInvoicesTable 
  },
  computed: {
    ...mapGetters({
      tab: 'saleInvoice/getTabSelected',
    }),
    tabSelected: {
      get () {
        return this.tab
      },
      set (value) {
        this.$store.commit('saleInvoice/setTabSelected', value)
      },
    }
  }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"sale-invoice-retention-table",attrs:{"resource":_vm.$data.$constants.RESOURCES.RESOURCE_RETENTIONS,"resourceStore":'saleInvoice',"columns":_vm.columns,"totals-configuration":_vm.totalsConfiguration,"filters":_vm.baseFilters,"loading":_vm.loading},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewSaleInvoice', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(client)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.client.name)+" ")])]}},{key:"cell(job)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.job.name)+" ")])]}},{key:"cell(total_retention)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" €")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.retention_expiration),expression:"item.retention_expiration"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(item.retention_expiration))+" ")])]}},{key:"cell(retention_expiration)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(data.item.retention_expiration)))])]}},{key:"cell(collateral_retention_collected)",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('b-badge',{staticClass:"px-1 d-inline-flex align-items-center",attrs:{"pill":"","variant":"light-success"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"25","color":"success"}}),_c('span',{staticClass:"text-capitalize font-weight-light badge-text text-success"},[_vm._v(" Si ")])],1):_c('b-badge',{staticClass:"px-1 d-inline-flex align-items-center",attrs:{"pill":"","variant":"light-danger"}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"25","color":"danger"}}),_c('span',{staticClass:"text-capitalize font-weight-light badge-text text-danger"},[_vm._v(" No ")])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-link',{staticClass:"d-inline-block text-indigo"},[_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePrintLinkClick(data.item.id)}}})],1),_c('b-link',{staticClass:"px-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewSaleInvoice', params: { id: data.item.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
          resource: _vm.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
          resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }),expression:"{\n          resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,\n          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n        }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.handleDeleteIconClick(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)]}}])}),_c('SaleInvoicesRetentionListFilters',{ref:"sale-invoices-retention-list-filters",attrs:{"baseFilters":_vm.baseFilters},on:{"filters-submit":function($event){return _vm.$store.commit('saleInvoice/setRetentionFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.invoice_date_max"
                @input="update('invoice_date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.invoice_date_min"
                @input="update('invoice_date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha vencimiento entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.retention_expiration_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.retention_expiration_max"
                @input="update('retention_expiration_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.retention_expiration_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.retention_expiration_min"
                @input="update('retention_expiration_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Job"
          label-for="sale-invoice-job"
        >
          <BaseSelect
            id="sale-invoice-job"
            :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
            :value="model.job"
            @input="update('job', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="sale-invoice-client"
        >
          <BaseSelect
            id="sale-invoice-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Recibida"
          label-for="sale-invoice-received-payment-document"
        >
          <v-select
            id="sale-invoice-received-payment-document"
            :value="model.collateral_retention_collected"
            :options="[{ name: 'si', value: 1 }, { name: 'no', value: 0 }]"
            label="name"
            :reduce="item => item.value"
            @input="update('collateral_retention_collected', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
            label="Estado"
            label-for="retention-status"
        >
          <StatusSelect
              id="retention-status"
              :value="model.status"
              :type="statusType"
              @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import { SALE_INVOICES_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from "@/components/status/select/StatusSelect.vue";

export default {
  name: 'SaleInvoicesRetentionListFiltersForm',
  components: {StatusSelect, BaseSelect },
  mixins: [FormMixin],
  data() {
    return {
      statusType: SALE_INVOICES_STATUS_TYPE,
    }
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
.custom-checkbox.custom-control,.custom-radio.custom-control {
  width: 100%;
}
</style>
